
import React from "react"
import ImgInvitatie from "./ImgInvitatie";
import gemeni from '../images/g.jpg'
import "../css/ImgInvitatie.css"
import background from "../images/bg2.jpg"

const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5" >
            <h1>Invitație</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4"  >
                <div className="card p-0 overflow-hidden h-100 shadow">
                  <h4 className="pt-5">Damian și Delia</h4>  
                    <h3>VĂ INVITĂ</h3>    
              
            <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                            <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                            </svg>
                            <br />
                        </h5>
                    <div class="card-body">
                        <h5>
                            Suntem foarte norocoși,<br/>
                            Avem 2 copii frumoși,<br/>
                            Suntem foarte fericiți,<br/>
                            Și vrem să sărbătorim<br/>
                            Cumetria lor.<br/>
                            Avem deosebită plăcere <br/>
                            Să vă invităm <br/>
                            Să petrecem împreună <br/>
                            În joc și cu voie bună,<br/>
                      
                        Pe data de  
                        </h5>
                       <h4>19 iulie 2024</h4>
                      
                            <h5>
                             Petrecerea va avea loc la
                            </h5>
                        <h4>Restaurantul „Primăvara”</h4>
                        <h5> începând cu ora 17:00.</h5>
                           
                        <h5> or. Soroca</h5>
                        
                        
                  
                     
                       
                      <h4 class="card-footer">Vă așteptăm cu drag!</h4>


                        {/** <ImgInvitatie />*/}

                        <p class="card-footer">Vom fi recunoscători dacă ați confirma prezența completând formularul!</p>
                        <a  href="#forms" className="btn btn-primary">Confirmați Prezența</a>
                    </div>




                </div>
            </div>
        </section>

    )
}

export default CardInvitatie;