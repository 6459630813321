import restaurant from "./images/PrimavaraSoroca.jpg";
import biserica from "./images/bis.jpg";
import img_card from "./images/gemeni.jpg";
import imgheadermb from "./images/FC2M.png";
import imgheader from "./images/FC3.png";
import imgheadermiini from "./images/bg2.jpg";
import imgheadermiinimb from "./images/bg2.jpg";
import logo from './images/logo.png'



const data = {
    introData: [{
        copilul: "",
        familia: "Damian și Delia",
        logo: logo,
        tata: "Cozma",
        mama: "Mădălina",
        data: "19 Iulie 2024",
        data_confirmare: "15 iunie 2024",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "czmapsenicinii@gmail.com", 
       tel: "+373 783 83 420",
       phone: "tel:+37378383420",
       viber: "viber://chat?number=%2B37378383420",
       whatsapp: "https://wa.me/+37378383420",
       messenger: "https://www.messenger.com/t/xxxx",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Primăvara",
            data: "19 iulie 2024, vineri, ora 17:00",
            adress: "or.Soroca",
            harta: "https://maps.app.goo.gl/7H5wqwequgPMqseK9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10644.103368487615!2d28.3058199!3d48.1675832!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40ccc1fd18c899cb%3A0x3576a691b8056277!2sRestaurant%20Primavara!5e0!3m2!1sro!2s!4v1716721558075!5m2!1sro!2s" 
        },
    

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Copii sunt un dar al lui Dumnezeu, un motiv de a crede în minuni, sunt pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;